














































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Image, Icon, Badge } from "vant";
import { monitorEvent } from "@/utils/youMengMonitor";
import EventBus from "@/utils/eventBus";

@Component({
  components: {
    [Image.name]: Image,
    [Icon.name]: Icon,
    [Badge.name]: Badge,
  },
})
export default class Order extends Mixins(Mixin) {
  @Prop({
    type: Object,
    default() {
      return {};
    },
  })
  showRemind!: order.ShowRemindRes;

  get showNum(): number {
    const showRemind = this.showRemind;
    if (!showRemind) {
      return 0;
    }
    return showRemind.showNum || 0;
  }

  get ticketNum(): number {
    const showRemind = this.showRemind;
    if (!showRemind) {
      return 0;
    }
    return showRemind.ticketNum || 0;
  }

  waitnum = 0;
  orderList = [
    {
      url: "order-payment.png",
      name: "待付款",
      num: 0,
    },
    {
      url: "order-delivery.png",
      name: "待发货",
      num: 0,
    },
    {
      url: "order-tickets.png",
      name: "待取票",
      num: 0,
    },
    {
      url: "order-goods.png",
      name: "待收货",
      num: 0,
    },
    {
      url: "order-all.png",
      name: "全部订单",
      num: 0,
    },
  ];
  toOrderList = false;

  activated(): void {
    if (this.UserModule.isLogin) {
      this.recieveOrders();
    } else {
      this.resetData();
    }
  }

  resetData(): void {
    this.waitnum = 0;
    this.orderList = [
      {
        url: "order-payment.png",
        name: "待付款",
        num: 0,
      },
      {
        url: "order-delivery.png",
        name: "待发货",
        num: 0,
      },
      {
        url: "order-tickets.png",
        name: "待取票",
        num: 0,
      },
      {
        url: "order-goods.png",
        name: "待收货",
        num: 0,
      },
      {
        url: "order-all.png",
        name: "全部订单",
        num: 0,
      },
    ];
  }

  goOrderList(): void {
    monitorEvent("MinePage_ClickOrder", "点击我的订单"); // 埋点：我的，点击我的订单
    if (this.waitnum === 0) {
      this.$router.push({
        path: `/personal-data/order`,
        query: { status: "ALL" },
      });
    } else {
      this.$router.push({
        path: `/personal-data/order`,
        query: { status: "PEND_PAYMENT" },
      });
    }
  }
  deactivated(): void {
    if (this.toOrderList) {
      EventBus.$emit("setOrderList");
    }
  }

  orderDetail(item: { name: string }): void {
    if (!this.UserModule.isLogin) {
      this.RouteRecordModule.GO_TO_LOGIN_PAGE_PERSIST({
        router: this.$router,
        newPage: true,
        goBackAfterLogin: true,
      });
      return;
    }
    let status = "";
    if (item.name == "全部订单") {
      status = "ALL";
    }
    if (item.name == "待付款") {
      status = "PEND_PAYMENT";
    }

    if (item.name == "待发货") {
      status = "WAIT_SEND";
    }
    if (item.name == "待取票") {
      status = "WAIT_PICK_BYSELF";
    }
    if (item.name == "待收货") {
      status = "WAIT_GET_TICKET";
    }
    this.toOrderList = true;
    this.$router.push({
      path: `/personal-data/order`,
      query: { status: status },
    });
  }

  recieveOrders(): void {
    this.$api.orderApi.ticketOrder.getOrderCountInfo(({ data }) => {
      this.orderList[0].num = data.pendPaymentNum!;
      this.orderList[1].num = data.waitSendNum!;
      this.orderList[2].num = data.waitPickBySefNum!;
      this.orderList[3].num = data.waitGetTicketNum!;
      // 不管是否为0,都应该让其赋值，否则当取消订单时，仍然会显示会有一笔未支付订单
      // if (data.pendPaymentNum !== 0) {
      // }
      this.waitnum = data.pendPaymentNum!;
    });
  }
}
