







































































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Progress } from "vant";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    [Progress.name]: Progress,
  },
})
export default class Assets extends Mixins(Mixin) {
  @Prop({
    type: Object,
    default() {
      return {};
    },
  })
  formAssets!: member.AssetCenter;
  @Prop({ default: true })
  isShowElectronicWallet!: boolean;

  // 余额总额
  get electronicWallet(): number | undefined {
    const formAssets = this.formAssets;
    if (!formAssets) {
      return undefined;
    }
    return formAssets.electronicWallet;
  }

  // 余额总额是否超过 1 万
  get beyondMoney(): boolean {
    const electronicWallet = this.electronicWallet;
    return !!electronicWallet && electronicWallet > 10000;
  }

  // 余额总额用于展示的信息
  get moneySplit(): Array<string> {
    const electronicWallet = this.electronicWallet;
    if (!electronicWallet) {
      return [];
    }
    const temp = String(
      electronicWallet > 10000 ? electronicWallet / 10000 : electronicWallet
    ).split(".");
    if (temp.length > 1 && temp[1]) {
      temp[1] = temp[1].substring(0, 2);
    } else if (temp.length > 1) {
      temp[1] = "00";
    } else {
      temp.push("00");
    }
    return temp;
  }

  // 总积分
  get integral(): number | undefined {
    const formAssets = this.formAssets;
    if (!formAssets) {
      return undefined;
    }
    return formAssets.integral;
  }

  // 总积分是否超过 1 万
  get beyondintegral(): boolean {
    const integral = this.integral;
    return !!integral && integral > 10000;
  }

  get integralSplit(): Array<string> {
    const integral = this.integral;
    if (!integral) {
      return [];
    }
    if (integral > 10000) {
      const temp = String(integral / 10000).split(".");
      // 截取小数点后 1 位
      temp[1] = temp[1].substring(0, 1);
      return temp;
    }
    return [];
  }

  goCoupon(): void {
    if (!this.UserModule.isLogin) {
      this.RouteRecordModule.GO_TO_LOGIN_PAGE_PERSIST({
        router: this.$router,
        newPage: true,
        goBackAfterLogin: true,
      });
      return;
    }
    monitorEvent("MinePage_ClickOfferList", "点击优惠券"); // 埋点：我的，点击优惠券
    this.$router.push("/my-coupons");
  }
  goGiftDetails(): void {
    if (!this.UserModule.isLogin) {
      this.RouteRecordModule.GO_TO_LOGIN_PAGE_PERSIST({
        router: this.$router,
        newPage: true,
        goBackAfterLogin: true,
      });
      return;
    }
    monitorEvent("MinePage_ClickCard", "点击礼品卡"); // 埋点：我的，点击礼品卡
    this.$router.push("/gift-card");
  }
  /**
   * 品牌会员卡
   */
  goBrandCardDetails(): void {
    this.$toast("品牌会员卡仅支持保利票务APP使用");
    // if (!this.UserModule.isLogin) {
    //   this.RouteRecordModule.GO_TO_LOGIN_PAGE_PERSIST({
    //     router: this.$router,
    //     newPage: true,
    //     goBackAfterLogin: true,
    //   });
    //   return;
    // }
    // monitorEvent("MinePage_ClickBrandCard", "点击品牌会员卡"); // 埋点：我的，点击品牌会员卡
    // this.$router.push("/brand-card");
  }
  goElectronicWallet(): void {
    if (!this.UserModule.isLogin) {
      this.RouteRecordModule.GO_TO_LOGIN_PAGE_PERSIST({
        router: this.$router,
        newPage: true,
        goBackAfterLogin: true,
      });
      return;
    }
    monitorEvent("MinePage_ClickCash", "点击余额"); // 埋点：我的，点击余额
    this.$router.push("/electronicWallet/index");
  }
  goIntegral(): void {
    if (!this.UserModule.isLogin) {
      this.RouteRecordModule.GO_TO_LOGIN_PAGE_PERSIST({
        router: this.$router,
        newPage: true,
        goBackAfterLogin: true,
      });
      return;
    }
    monitorEvent("MinePage_ClickBonus", "点击积分"); // 埋点：我的，点击积分
    this.$router.push("/integral");
  }
}
