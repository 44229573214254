






























































import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Col, Row, Button, Image, Sticky } from "vant";
import Order from "@/views/Mine/Order.vue";
import Assets from "@/views/Mine/Assets.vue";
import More from "@/views/Mine/More.vue";
import Member from "./Mine/Member.vue";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  name: "mine",
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [Image.name]: Image,
    [Sticky.name]: Sticky,
    Member,
    Order,
    Assets,
    More,
  },
})
export default class Mine extends Mixins(Mixin) {
  isYby = false;
  moreList = [
    { imageUrl: "o6.png", name: "我的评价", url: "/my-comment" },
    { imageUrl: "o1.png", name: "票夹", url: "/wallet" },
    { imageUrl: "o2.png", name: "常用观演人", url: "/audience-list" },
    { imageUrl: "o3.png", name: "收货地址", url: "/address" },
    {
      imageUrl: "o4.png",
      name: "剧乐部",
      url: "/enter-activity",
      needLogin: false,
    },
    // { imageUrl: "o5.png", name: "集印花", url: "/collect-printing" }, // 暂时开放 测试版 发生产需注掉
    {
      imageUrl: "o9.png",
      name: "商务合作",
      url: "/personal-data/contactm",
      needLogin: false,
    },
    // { imageUrl: "o7.png", name: "收藏订阅", url: "/my-comment" },
    // { imageUrl: "o8.png", name: "云缴费", url: "/my-comment" },
    // { imageUrl: "o7.png", name: "收藏订阅", "/personal-data/mycollections" },
    // { imageUrl: "o8.png", name: "云缴费" },
    {
      imageUrl: "o11.png",
      name: "观察员报名",
      url: "",
      needLogin: true,
      show: false,
    },
  ];
  formAssets: member.AssetCenter = {}; // 我的资产
  showRemind: order.ShowRemindRes = {}; //

  isShowElectronicWallet = true; //是否展示电子钱包
  get userName(): string {
    let UserModule = this.UserModule;
    if (UserModule.isLogin) {
      let nickName = UserModule.nickName;
      return nickName || UserModule.username;
    } else {
      this.isYby = false;
    }
    return "登录/注册";
  }

  get headImg(): string {
    let UserModule = this.UserModule;
    if (UserModule.isLogin) {
      return UserModule.headImg;
    }
    return "";
  }

  activated(): void {
    if (this.UserModule.isLogin) {
      this.InterfaceCacheModule.getCacheProductIds(() => {
        this.InterfaceCacheModule.getDatas([
          // 刷新个人信息
          {
            cacheKey: "cuUser.memberInfo",
            apiMethod: this.$api.memberApi.cuUser.memberInfo,
            expireInterval: 60, // 个人中心的过期时间间隔是 60 秒
            callback: ({ data }) => {
              this.isYby = data.commentIdentityCode === "02";
              if (data.headImg) {
                this.UserModule.IMG_CHANGE_PERSIST(data.headImg);
              }
            },
          },
          // 我的资产
          {
            cacheKey: "cuUser.assetCenter",
            apiMethod: this.$api.memberApi.cuUser.assetCenter,
            expireInterval: 60, // 个人中心的过期时间间隔是 60 秒
            callback: ({ data }) => {
              this.formAssets = data;
            },
          },
          // 今天上演场次提示
          {
            cacheKey: "ticketFolder.getShowRemind",
            apiMethod: this.$api.orderApi.ticketFolder.getShowRemind,
            expireInterval: 60, // 个人中心的过期时间间隔是 60 秒
            callback: ({ data }) => {
              this.showRemind = data;
            },
          },
        ]);
      });
    } else {
      this.formAssets = {};
      this.showRemind = {};
    }
    // 测试环境，分销id为278（翼支付）
    if (this.$route.query.templateType == "BEST_PAY") {
      this.isShowElectronicWallet = false;
    }
    this.getIsShow();
  }

  // 状态栏高度
  get statusBar(): number {
    let immersed = 0;
    let ms = /Html5Plus\/.+\s\(.*(Immersed\/(\d+\.?\d*).*)\)/gi.exec(
      navigator.userAgent
    );
    if (ms && ms.length >= 3) {
      // 当前环境为沉浸式状态栏模式
      immersed = parseFloat(ms[2]); // 获取状态栏的高度
    }
    return immersed * 0.01;
  }
  // 修复苹果手机会员背景图片,头像无法加载的问题，重新调接口

  getIsShow() {
    this.$api.dictionaryApi.performance.businessPartner(({ data }) => {
      let showMap = {} as any;
      data.forEach((it) => {
        if (it.code) {
          showMap[it.code] = it.open;
        }
      });
      this.moreList.forEach((item) => {
        if (item.name === "观察员报名") {
          item.show = showMap["03"];
        }
      });
    });
  }
  /**
   * 跳转到登录页
   */
  goToLoginPage(): void {
    sessionStorage.setItem("checked", JSON.stringify(false));
    this.RouteRecordModule.GO_TO_LOGIN_PAGE_PERSIST({
      router: this.$router,
      newPage: true,
    });
  }

  /**
   * 去设置页面
   */
  goSet(): void {
    monitorEvent("MinePage_ClickSet", "点击设置"); // 埋点：我的，点击设置
    this.$router.push(`/personal-data/set`);
  }

  /**
   * 去个人资料
   */
  goToPersonalData(): void {
    monitorEvent("Click_Mine", "点击头像"); // 埋点：我的，点击头像
    this.goToPageOrLogin("/personal-data/index");
  }

  goToPageOrLogin(path: string): void {
    if (this.UserModule.isLogin) {
      this.$router.push(path);
    } else {
      this.RouteRecordModule.GO_TO_LOGIN_PAGE_PERSIST({
        router: this.$router,
        newPage: true,
      });
    }
  }
}
